var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid p-1 pl-1 pr-1",style:({
    backgroundImage: ("url(" + (require('@/assets/images/banner/banner-2.jpg')) + ")"),
  })},[_c('b-card',{staticClass:"nopadding"},[_c('div',{staticClass:"backColor mb-2 pb-1 pt-1 d-flex justify-content-center "},[_c('span',{staticClass:"my-auto h1 text-dark"},[_vm._v("Faire une suggestion")])]),_c('div',{staticClass:"row mx-1 mb-1"},[_c('div',{staticClass:"col-sm-12 backColor text-dark h3 py-2"},[_vm._v("Nous Contacter")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 row h-25 w-100 mb-2 p-2"},[_c('div',{staticClass:"col-sm-12 "},[_vm._v(" Merci de nous contacter en écrivant à cette adresse email: "),_c('a',{attrs:{"href":"mailto:"}},[_vm._v("mtca.dsisa@gouv.bj")]),_c('br'),_vm._v(" Nous mettons tout en oeuvre pour répondre le plus rapidement possible. "),_c('br')]),_c('div',{staticClass:"col-sm-12  mt-1"},[_c('span',{staticClass:"mt-2"},[_vm._v("Heures d'Ouverture : "),_c('br'),_vm._v(" Lundi - Vendredi 8h - 17h30 ")])])]),_c('div',{staticClass:"col-lg-6 row w-100"},[_c('validation-observer',{ref:"formRules"},[_c('div',{staticClass:"col-12 row row"},[_c('span',{staticClass:"col-lg-6 col-sm-12 col-xs-12"},[_c('span',[_c('strong',[_vm._v("Nom et Prénom(s)")])]),_vm._v(" "),_c('br'),_c('span',[_c('b-form-input',{model:{value:(_vm.objectMsg.nomPrenoms),callback:function ($$v) {_vm.$set(_vm.objectMsg, "nomPrenoms", $$v)},expression:"objectMsg.nomPrenoms"}})],1)]),_c('span',{staticClass:"col-lg-6 col-sm-12 col-xs-12"},[_c('span',[_c('strong',[_vm._v("Mail")])]),_c('span',[_c('validation-provider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.objectMsg.mail),callback:function ($$v) {_vm.$set(_vm.objectMsg, "mail", $$v)},expression:"objectMsg.mail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('span',{staticClass:"col-12 mt-1"},[_c('span',[_c('strong',[_vm._v("Sujet")])]),_c('span',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.optionsSujet},model:{value:(_vm.objectMsg.sujet),callback:function ($$v) {_vm.$set(_vm.objectMsg, "sujet", $$v)},expression:"objectMsg.sujet"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('span',{staticClass:"col-12 mt-1"},[_c('span',[_c('strong',[_vm._v("Message")])]),_c('span',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"rows":"3"},model:{value:(_vm.objectMsg.message),callback:function ($$v) {_vm.$set(_vm.objectMsg, "message", $$v)},expression:"objectMsg.message"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('span',{staticClass:"col-12 mt-1",on:{"click":function($event){return _vm.envoyer()}}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"}},[_vm._v(" Envoyer ")])],1)])])],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }