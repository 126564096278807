/* eslint-disable global-require */
<template>
  <div class="container-fluid p-1 pl-1 pr-1"
    :style="{
      backgroundImage: `url(${require('@/assets/images/banner/banner-2.jpg')})`,
    }">

    <b-card  class="nopadding">
      <div class="backColor mb-2 pb-1 pt-1 d-flex justify-content-center ">
        <span class="my-auto h1 text-dark">Faire une suggestion</span>
      </div>
      <div class="row mx-1 mb-1">
        <div class="col-sm-12 backColor text-dark h3 py-2">Nous Contacter</div>

        <div class="row">
          <!-- infos -->
          <div class="col-lg-6 row h-25 w-100 mb-2 p-2">
            <div class="col-sm-12 ">
              Merci de nous contacter en écrivant à cette adresse email: 
              <a href="mailto:">mtca.dsisa@gouv.bj</a>  
              <br>
              Nous mettons tout en oeuvre pour répondre le plus rapidement possible. <br>
              <!-- <strong>Tel: 90 99 98 97</strong> -->
            </div>
            <div class="col-sm-12  mt-1">
              <span class="mt-2">Heures d'Ouverture : <br> Lundi - Vendredi 8h - 17h30 </span>
            </div>
          </div>
          <!-- forms -->
          <div class="col-lg-6 row w-100">
            <validation-observer ref="formRules">
              <div class="col-12 row row">
                <span class="col-lg-6 col-sm-12 col-xs-12">
                  <span> <strong>Nom et Prénom(s)</strong> </span> <br>
                  <span> 
                      <b-form-input
                        v-model="objectMsg.nomPrenoms"
                      />
                  </span>
                </span>
                
                <span class="col-lg-6 col-sm-12 col-xs-12">
                  <span> <strong>Mail</strong> </span>
                  <span> 
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|email"
                    >
                      <b-form-input
                        v-model="objectMsg.mail"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </span>
                </span>
                
                <span class="col-12 mt-1">
                  <span> <strong>Sujet</strong> </span>
                  <span> 
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <v-select
                        v-model="objectMsg.sujet"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="optionsSujet"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </span>
                </span>
    
                <span class="col-12 mt-1">
                  <span> <strong>Message</strong> </span>
                  <span> 
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-form-textarea
                        rows="3"
                        v-model="objectMsg.message"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </span>
                </span>
                
                <span class="col-12 mt-1" @click="envoyer()">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                  >
                    Envoyer
                  </b-button>
                </span>
                
              </div>
            </validation-observer>
          </div>
  

        </div>



      </div>
    </b-card>

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BForm,
  BInputGroup,
  BFormInput,
  BCardText,
  BInputGroupPrepend,
  BButton,
  BCardHeader,
  BFormTextarea,
} from "bootstrap-vue";

import utilsService from "@/services/utils/utils.service";

import TipsModalCreateFromLastRecord from "@/components/TipsModalCreateFromLastRecord.vue";
import localstorageService from "@/services/localstorage/localstorage.service";
import { mapMutations, mapActions } from "vuex";
import vSelect from "vue-select";
import locale from "@core/layouts/components/app-navbar/components/Locale.vue";
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { quillEditor } from 'vue-quill-editor'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// const kb = utilsService.getListFormulaires()
// --------------------------------------------------
import paramsStoreModule from '@/store/params'
import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BCardHeader,BFormTextarea,
    ValidationProvider, ValidationObserver,

    TipsModalCreateFromLastRecord,
    vSelect,
    quillEditor,
    locale,
  },
  directives: {
    Ripple,
  },
  setup() {
    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Description de votre message',
    }
    const formulairesByGroup = utilsService.getListFormulairesByGroup();
    const appLocales = [
      { code: "en", name: "anglais" },
      { code: "fr", name: "francais" },
    ];

    const requiredStoreModules = [
      { path: 'params', module: paramsStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)

    return { requiredStoreModules }
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules)
  },
  data() {
    return {
      objectMsg: {
        nomPrenoms: '',
        mail: '',
        sujet: '',
        message: '',
      },
      optionsSujet: [
        'Problèmes d\'utilisation','Suggestions','Autres'
      ],
    };
  },

  computed: {
    // ...mapState("auth", {
    //   isAuthenticated: "isAuthenticated",
    // }),
    // filteredKB() {
    //   const knowledgeBaseSearchQueryLower =
    //     this.knowledgeBaseSearchQuery.toLowerCase();
    //   // if(this.selectedSectorId){ // filtre choix de secteur
    //   return this.kb.filter((item) => {
    //     const condition =
    //       (this.selectedSectorId
    //         ? item.sector == this.selectedSectorId
    //         : true) &&
    //       item.title.toLowerCase().includes(knowledgeBaseSearchQueryLower);

    //     return condition;
    //   });
    // },
    // currentLocaleName() {
    //   if (this.$i18n.locale === "fr") return "francais";
    //   return "anglais";
    // },
  },
  watch: {
    // selectedSector: {
    //   handler(val, old) {
    //     console.log("home ---> selectedSector: ", val, old);
    //     this.selectedSectorId = this.selectedSector._id
    //       ? this.selectedSector._id
    //       : null;
    //     this.filteredKB;
    //   },
    // },
  },
  mounted() {
    // console.log(this.formulairesByGroup);
    if (localstorageService.getUserId()) {
      this.kbOptions = { publicOnly: false, privateOnly: true };
    } else {
      this.kbOptions = { publicOnly: true, privateOnly: false };
    }
    // this.loadSectors();
  },
  methods: {
    ...mapActions("params", {
      action_sendMailNousContacter: "sendMailNousContacter",
    }),
    // ---------------------------------------------------------
    envoyer(){
      this.$refs.formRules.validate().then(async success => {
        if (success) {
          console.log('this.objectMsg: 🩸🩸🩸 ', this.objectMsg)

          this.action_sendMailNousContacter(this.objectMsg)
            .then(res => {
              console.log('res: ', res)
              if(res.status === 200){

                this.objectMsg = {
                  nomPrenoms: '',
                  mail: '',
                  sujet: '',
                  message: '',
                }
                
                // success alert
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opération réussie',
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })

                // redition to home
                this.$router.replace({ name: 'home-public' })

              }
            })
            .catch(err => {
              console.log('err: ', err)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: err.message || 'Une erreur est survenue',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    }
    // loadSectors() {
    //   const currentParams = {
    //     page: this.paginationData.currentPage - 1,
    //     perPage: this.paginationData.perPage,
    //   };
    //   this.fetchSecteurs({ params: currentParams || null })
    //     .then((res) => {
    //       this.sectors = res.data.secteurs;
    //       this.sectorsTotal = res.data.total;
    //       this.sectors.forEach((currentValue, index, arr) => {
    //         this.sectorsCustom.push({
    //           nom: currentValue.nom,
    //           _id: currentValue._id,
    //         });
    //       });
    //       // console.log('res this.sectorsCustom: ', this.sectorsCustom)
    //     })
    //     .catch((err) => {
    //       this.isLoadingTableData = false;
    //       console.log(err);
    //     });
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-knowledge-base.scss";

.backColor {
  background-color: #c0c6c8;
}
.card-body {
  padding: 0 !important;
  margin: 0 !important;
}

.quill-editor {
    .ql-container.ql-snow {
      border-bottom: 0 !important;
    }
  }
</style>
